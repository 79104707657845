import React from 'react';
import './styles.scss';

const MustTry = (props) => {
    return (
        <div className='triviaContainer'>
            <div className='MustryGamesWrap'>
                <div className='MustryGamesHeader'>
                    <h3>
                        Must-try games for your <br />
                        next happy hour
                    </h3>
                </div>
                <div className='MustryGamesInner'>
                    {props.data.map((data, index) => {
                        return (
                            <div className='MustryGamesCard' key={index}>
                                <img data-src={data.Image} alt={''} />
                                <h5>{data.Header}</h5>
                                <p>{data.Para}</p>
                                <h6>
                                    {data.SubHeader} <span>{data.SubText}</span>
                                </h6>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MustTry;
